<script>
	import Header from "./components/Header.svelte";
	import Footer from "./components/Footer.svelte";
	import { Router, Route} from "svelte-routing";
	import { Home, Download, Resources, UseCasesOverview, SignUp } from "./pages/pages.js";
	import UseCaseDetail from "./pages/UseCaseDetail.svelte";
    import {menuOptions, useCases} from "./data/UseCasesOverview/usecases.json";
	export let url="";

</script>

<style lang="scss">.app__content {
  padding-top: 50px;
  min-height: calc(100vh - 350px);
  height: 100%;
  width: 100%; }

/*# sourceMappingURL=x.map */</style>

<div class="app">
	<SignUp/>
	<Header/>
	<div class="app__content">
		<Router url="{url}">
			<Route path="download" component="{Download}"/>
			{#each useCases as item}
				<Route path="{item.path}">
					<UseCaseDetail data={item}/>
				</Route>
			{/each}
			<!-- <Route path="resources" component="{Resources}"/> -->
			<Route path="use-cases/*" component="{UseCasesOverview}"/>
			<Route component="{Home}"/>
		</Router>
	</div>
	<Footer/>
</div>

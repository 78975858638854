<script>
    export let image;
    export let title;
    export let date = null;
    export let avatar = null;
    export let subtitle;
    export let link;
    import { Link} from "svelte-routing";
</script>
<style lang="scss">.card {
  height: 350px;
  position: relative;
  background: #ffffff;
  border: 1px solid #efefef; }
  .card__image {
    height: 50%;
    width: 100%; }
    .card__image__overlay {
      height: 100%;
      width: 100%;
      background: rgba(0, 0, 0, 0.2); }
  .card__content {
    padding: 20px 10px;
    height: 50%;
    width: 100%; }
  .card__avatar {
    width: 30px;
    height: 30px;
    position: relative;
    overflow: hidden;
    border-radius: 50%;
    margin-right: 10px; }
    .card__avatar img {
      margin: 0 auto;
      margin-left: -25%;
      height: 100%;
      width: auto; }
  .card__bottom {
    display: flex;
    flex-wrap: wrap;
    width: calc(100% - 20px);
    align-items: center;
    position: absolute;
    bottom: 20px; }
  .card__action {
    display: none;
    position: absolute;
    bottom: 20px;
    color: #00a8c1; }
    .card__action img {
      height: 12px;
      margin: 3px 10px 0; }
  .card:hover {
    box-shadow: 0px 3px 4px 1px #efefef; }
    .card:hover .card__content {
      height: 100%;
      position: absolute;
      top: 0;
      background: #f5faf8;
      transition: background linear .3s; }
    .card:hover .card__bottom {
      position: static; }
    .card:hover .card__action {
      display: block; }

.tag {
  display: inline-block;
  white-space: no-wrap; }

.tag:last-child:after {
  content: ""; }

.tag:after {
  content: "\2022";
  padding: 0 4px; }

p {
  font-size: .875em;
  margin: 0;
  color: #4c4d5f; }

h5 {
  margin: 0;
  color: #1f2238; }

:global(.card-link):hover {
  text-decoration: none; }

/*# sourceMappingURL=x.map */</style>
<Link getProps={() => {return {class: "card-link"}}} to="{link}">
    <div class="card">
        <div class="card__image" style="background: url({image}) 0% 0% / cover no-repeat;">
            <div class="card__image__overlay"></div>
        </div>
        <div class="card__content">
            {#if date}
                <p>{date}</p>
            {/if}
            <h5>{title}</h5>
            <div class="card__bottom">
                {#if avatar}
                    <div class="card__avatar">
                        <img src="{avatar}" alt="card-avatar">
                    </div>
                {/if}
                {#if Array.isArray(subtitle)}
                    {#each subtitle as item}
                        <p class="tag">{item}</p>
                    {/each}
                {:else}
                    <p>{subtitle}</p>
                {/if}
            </div>
            <div class="card__action">
                Read More <span><img class="arrow" src="src/resources/img/arrow.svg" alt="arrow"></span>
            </div>
        </div>
    </div>
</Link>
<script>
    export let image;
    export let title;
    export let description;
</script>
<style lang="scss">.video-card {
  height: 400px; }
  .video-card__video {
    height: 50%; }
  .video-card__content {
    height: 50%; }

/*# sourceMappingURL=x.map */</style>

<div class="video-card">
    <div class="video-card__video" style="background: url({image}); background-size: cover;"></div>
    <div class="video-card__content">
        <h4>{title}</h4>
        <p>{description}</p>
    </div>
</div>
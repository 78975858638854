<script>
    export let title;
    export let link;
    export let color = "#ffffff";
    export let background = "#00a8c1";
</script>
<style lang="scss">.resource-card {
  height: 350px;
  width: 260px;
  padding: 50px 40px;
  position: relative; }
  .resource-card__img {
    position: absolute;
    bottom: 50px;
    left: 40px;
    width: calc(100% - 80px);
    height: auto; }
    .resource-card__img img {
      width: 100%;
      height: auto; }

h3 {
  font-weight: 400;
  margin: 0; }

p {
  font-size: .875em;
  margin: 0; }

/*# sourceMappingURL=x.map */</style>
<div class="resource-card" style="background: {background};">
    <a href="{link}" target="_blank" style="color: {color};">
        <p>SEMOSS</p>
        <h3>{title}</h3>
    </a>
    <div class="resource-card__img">
        <img src="src/resources/img/dots.png" alt="dots-image">
    </div>
</div>
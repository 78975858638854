<script>
    import { Router, Link } from "svelte-routing";
</script>

<style lang="scss">.footer {
  width: 100%;
  height: 350px;
  background: #ffffff;
  padding-top: 100px; }
  .footer__content {
    height: 250px;
    width: 80%;
    margin: auto; }
  .footer__top {
    display: flex;
    justify-content: space-between; }
    .footer__top__logo {
      width: 65%; }
      .footer__top__logo img {
        height: 32px;
        width: auto; }
    .footer__top__site-page {
      display: flex;
      width: 35%;
      justify-content: space-between; }
      .footer__top__site-page__block {
        padding: 0 20px; }
        .footer__top__site-page__block:last-child {
          padding-right: 0; }
    .footer__top p {
      color: #4c4d5f; }
  .footer__bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 0; }
    .footer__bottom p {
      color: #6e758b;
      font-size: 0.875em; }
  .footer__icons {
    display: flex; }
    .footer__icons a {
      width: 30px;
      height: 30px;
      margin-left: 10px; }
      .footer__icons a svg {
        width: 100%;
        height: 100%; }

ul {
  list-style: none;
  padding: 0; }

li {
  margin: 10px 0; }

h6 {
  margin-top: 0;
  text-transform: uppercase;
  font-size: .8em; }

a {
  color: #6e758b;
  font-size: 0.875em; }
  a:hover {
    text-decoration: none;
    color: #00a8c1; }

:global(.footer-links) {
  color: #6e758b;
  font-size: 0.875em; }
  :global(.footer-links):hover {
    color: #00a8c1;
    text-decoration: none; }

@media only screen and (max-width: 1080px) {
  .footer__top__logo {
    display: none; }
  .footer__top__site-page {
    width: 100%; } }

/*# sourceMappingURL=x.map */</style>


<div class="footer">
    <div class="footer__content">
        <div class="footer__top">
            <div class="footer__top__logo">
                <img src="src/resources/img/semoss_logo_dark.svg" alt="logo">
                <p>Federated Analytics,<br> Amplified Intelligence</p>
            </div>
            <div class="footer__top__site-page">
                <Router>
                <div class="footer__top__site-page__block">
                    <h6>Help</h6>
                    <ul>
                        <li>
                            <Link to="/" getProps={() => {return {class: "footer-links"}}}>
                                About
                            </Link>
                        </li>
                        <li>
                            <a href="http://semoss.org/SemossDocumentation/" target="_blank">
                                User Guide
                            </a>
                        </li>
                        <li>
                            <a href="mailto:info@semoss.org">Contact Us</a>
                        </li>
                    </ul>
                </div>
                <div class="footer__top__site-page__block">
                    <h6>Product</h6>
                    <ul>
                        <li>
                            <a href="https://play.semoss.org/SemossWeb/#!/login" target="_blank">
                                Try It
                            </a>
                        </li>
                        <li>
                            <Link to="download" getProps={() => {return {class: "footer-links"}}}>
                                Download
                            </Link>
                        </li>
                        <li>
                            <Link to="use-cases" getProps={() => {return {class: "footer-links"}}}>
                                Use Cases
                            </Link>
                        </li>
                    </ul>
                </div>
                </Router>
            </div>
        </div>
        <div class="footer__bottom">
            <p>Copyright 2019</p>
            <div class="footer__icons">
                <a href="https://medium.com/@semossinfo" target="_blank">
                    <svg aria-hidden="true" focusable="false" data-prefix="fab" data-icon="medium" class="svg-inline--fa fa-medium fa-w-14" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M0 32v448h448V32H0zm372.2 106.1l-24 23c-2.1 1.6-3.1 4.2-2.7 6.7v169.3c-.4 2.6.6 5.2 2.7 6.7l23.5 23v5.1h-118V367l24.3-23.6c2.4-2.4 2.4-3.1 2.4-6.7V199.8l-67.6 171.6h-9.1L125 199.8v115c-.7 4.8 1 9.7 4.4 13.2l31.6 38.3v5.1H71.2v-5.1l31.6-38.3c3.4-3.5 4.9-8.4 4.1-13.2v-133c.4-3.7-1-7.3-3.8-9.8L75 138.1V133h87.3l67.4 148L289 133.1h83.2v5z"></path></svg>
                </a>
                <a href="https://www.youtube.com/channel/UCG_l6gK3kdisiuEn81-mpXg/videos" target="_blank">
                    <svg aria-hidden="true" focusable="false" data-prefix="fab" data-icon="youtube" class="svg-inline--fa fa-youtube fa-w-18" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path fill="currentColor" d="M549.655 124.083c-6.281-23.65-24.787-42.276-48.284-48.597C458.781 64 288 64 288 64S117.22 64 74.629 75.486c-23.497 6.322-42.003 24.947-48.284 48.597-11.412 42.867-11.412 132.305-11.412 132.305s0 89.438 11.412 132.305c6.281 23.65 24.787 41.5 48.284 47.821C117.22 448 288 448 288 448s170.78 0 213.371-11.486c23.497-6.321 42.003-24.171 48.284-47.821 11.412-42.867 11.412-132.305 11.412-132.305s0-89.438-11.412-132.305zm-317.51 213.508V175.185l142.739 81.205-142.739 81.201z"></path></svg>
                </a>
                <a href="https://github.com/semoss" target="_blank">
                    <svg aria-hidden="true" focusable="false" data-prefix="fab" data-icon="github" class="svg-inline--fa fa-github fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 496 512"><path fill="currentColor" d="M165.9 397.4c0 2-2.3 3.6-5.2 3.6-3.3.3-5.6-1.3-5.6-3.6 0-2 2.3-3.6 5.2-3.6 3-.3 5.6 1.3 5.6 3.6zm-31.1-4.5c-.7 2 1.3 4.3 4.3 4.9 2.6 1 5.6 0 6.2-2s-1.3-4.3-4.3-5.2c-2.6-.7-5.5.3-6.2 2.3zm44.2-1.7c-2.9.7-4.9 2.6-4.6 4.9.3 2 2.9 3.3 5.9 2.6 2.9-.7 4.9-2.6 4.6-4.6-.3-1.9-3-3.2-5.9-2.9zM244.8 8C106.1 8 0 113.3 0 252c0 110.9 69.8 205.8 169.5 239.2 12.8 2.3 17.3-5.6 17.3-12.1 0-6.2-.3-40.4-.3-61.4 0 0-70 15-84.7-29.8 0 0-11.4-29.1-27.8-36.6 0 0-22.9-15.7 1.6-15.4 0 0 24.9 2 38.6 25.8 21.9 38.6 58.6 27.5 72.9 20.9 2.3-16 8.8-27.1 16-33.7-55.9-6.2-112.3-14.3-112.3-110.5 0-27.5 7.6-41.3 23.6-58.9-2.6-6.5-11.1-33.3 2.6-67.9 20.9-6.5 69 27 69 27 20-5.6 41.5-8.5 62.8-8.5s42.8 2.9 62.8 8.5c0 0 48.1-33.6 69-27 13.7 34.7 5.2 61.4 2.6 67.9 16 17.7 25.8 31.5 25.8 58.9 0 96.5-58.9 104.2-114.8 110.5 9.2 7.9 17 22.9 17 46.4 0 33.7-.3 75.4-.3 83.6 0 6.5 4.6 14.4 17.3 12.1C428.2 457.8 496 362.9 496 252 496 113.3 383.5 8 244.8 8zM97.2 352.9c-1.3 1-1 3.3.7 5.2 1.6 1.6 3.9 2.3 5.2 1 1.3-1 1-3.3-.7-5.2-1.6-1.6-3.9-2.3-5.2-1zm-10.8-8.1c-.7 1.3.3 2.9 2.3 3.9 1.6 1 3.6.7 4.3-.7.7-1.3-.3-2.9-2.3-3.9-2-.6-3.6-.3-4.3.7zm32.4 35.6c-1.6 1.3-1 4.3 1.3 6.2 2.3 2.3 5.2 2.6 6.5 1 1.3-1.3.7-4.3-1.3-6.2-2.2-2.3-5.2-2.6-6.5-1zm-11.4-14.7c-1.6 1-1.6 3.6 0 5.9 1.6 2.3 4.3 3.3 5.6 2.3 1.6-1.3 1.6-3.9 0-6.2-1.4-2.3-4-3.3-5.6-2z"></path></svg>
                </a>
            </div>
        </div>
    </div>
</div>
<script>
    import { Link } from "svelte-routing";
    import { onMount, onDestroy} from "svelte";
    import { transparentHeader, staticHeader, inverseHeader } from "../store.js";
    import DefaultArticleBlock from "../components/DefaultArticleBlock.svelte";
    import SideArticleBlock from "../components/SideArticleBlock.svelte";
    export let data;
    onMount(() => {
        transparentHeader.set(true);
        staticHeader.set(true);
        inverseHeader.set(true);
        window.scrollTo(0,0);
    });
    onDestroy(() => {
        transparentHeader.set(false);
        staticHeader.set(false);
        inverseHeader.set(false);     
    });
    const articleOptions = {
        "default": DefaultArticleBlock,
        "side": SideArticleBlock
    };
    function submitForm() {
        var formData = new URLSearchParams(new FormData(document.getElementById('demo-form')));
        formData.append("Submit", "Submit");
        formData.append("campaign_id", "971ede46-91ed-1c76-6a9c-5d8028c7e3f8");
        formData.append("moduleDir", "Contacts");
        fetch('https://semoss.org/crm/index.php?entryPoint=WebToPersonCapture', {
            method: 'POST',
            body: formData,
            headers:{
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        })
        .then(response => console.log("response", response))
    }
</script>

<style lang="scss">.content__top {
  display: flex;
  align-items: center;
  padding-bottom: 100px; }

.content__persona {
  width: 20%;
  text-align: center;
  padding: 20px; }
  .content__persona p {
    margin: 0; }

.content__description {
  width: 60%;
  background: #f8f8f9;
  margin-top: -50px;
  position: relative;
  padding: 50px; }
  .content__description:before {
    content: "";
    display: block;
    width: 200px;
    height: 200px;
    position: absolute;
    right: -100px;
    bottom: -100px;
    background: url(/src/resources/img/dots2.svg);
    z-index: -1; }
  .content__description__header {
    margin: 0; }
  .content__description__text {
    white-space: pre-wrap; }

.actions {
  background: url(/src/resources/img/straight-lines.png);
  background-repeat: no-repeat;
  background-position-x: left; }
  .actions__content {
    width: 80%;
    margin: auto;
    display: flex;
    justify-content: space-between;
    padding-top: 150px; }
    .actions__content div:first-child {
      border-bottom: 1px solid #e4e5e7; }
    .actions__content__block {
      padding-bottom: 40px; }
      .actions__content__block__btn {
        position: relative; }
        .actions__content__block__btn:before {
          content: "";
          height: 100px;
          width: 106px;
          background-repeat: no-repeat;
          display: block;
          position: absolute;
          background: url(/src/resources/img/small_leaf.svg);
          background-size: contain;
          top: -15px;
          right: -73px;
          z-index: -1; }

.arrow {
  height: 12px;
  margin: 3px 10px 0; }

.avatar {
  width: 100px;
  height: 100px;
  position: relative;
  overflow: hidden;
  border-radius: 50%;
  margin: auto; }

.avatar img {
  margin: 0 auto;
  margin-left: -25%;
  height: 100%;
  width: auto; }

.header {
  margin-top: -50px;
  padding-top: 50px;
  height: 500px;
  position: relative; }
  .header__content {
    width: 60%;
    margin: auto;
    top: 300px;
    position: relative;
    color: #ffffff; }
    .header__content h2 {
      margin: 0;
      font-weight: 300;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden; }
    .header__content p {
      margin: 0; }
  .header__overlay {
    height: 100%;
    width: 100%;
    position: absolute;
    margin-top: -50px;
    background: rgba(0, 0, 0, 0.7); }

.tag {
  display: inline-block;
  margin: 0; }

.tag:last-child:after {
  content: ""; }

.tag:after {
  content: "\2022";
  padding: 0 4px; }

@media only screen and (max-width: 1080px) {
  .header__content {
    top: 25%; }
    .header__content h2 {
      white-space: normal;
      overflow: visible; }
  .content__top {
    flex-wrap: wrap;
    padding: 0; }
  .content__persona {
    margin-top: -70px;
    width: 100%; }
  .content__description {
    width: 80%;
    margin: -50px auto 0; }
    .content__description:before {
      display: none; }
  .actions {
    background: none; }
    .actions__content {
      flex-wrap: wrap;
      padding: 0; }
      .actions__content__block {
        width: 100%; }
        .actions__content__block input {
          width: 100%; }
        .actions__content__block button {
          margin-top: 10px; } }

/*# sourceMappingURL=x.map */</style>

<div>
    <div class="header" style="background: url(/{data.image}); background-size: cover; background-repeat: no-repeat;">
        <div class="header__overlay"></div>
        <div class="header__content">
            <h2>{data.title}</h2>
            {#each data.subtitle as item}
                <p class="tag smss-caption">{item}</p>
            {/each}
        </div>
    </div>
    <div class="content">
        <div class="content__top">
            <div class="content__persona">
                <!-- <div class="avatar">
                    <img src="{data.persona.image}" alt="avatar-img">
                </div>
                <p class="smss-caption--small smss-semibold">{data.persona.name}</p>
                <p class="smss-footnote">{data.persona.role}</p> -->
            </div>
            <div class="content__description">
                <!-- <p class="content__description__header smss-caption--small smss-semibold">{data.date}</p>  -->
                <p class="content__description__text smss-caption">{data.description}</p>
                <a href="{data.link}" target="_blank">Demo now <span><img class="arrow" src="src/resources/img/arrow.svg" alt="arrow"></span></a>
            </div>
        </div>
        <div>
            {#each data.content as block}
                <svelte:component this={articleOptions[block.component]} data={block}/>
            {/each}
        </div>
    </div>
    <div class="actions">
        <div class="actions__content">
            <div class="actions__content__block">
                <h3>Interested in trying?</h3>
                <Link to="use-cases">
                    Show more use cases
                    <img class="arrow" src="src/resources/img/arrow.svg" alt="arrow">
                </Link>
            </div>
            <div class="actions__content__block">
                <h3 class="input__label">Interested in a demo?</h3>
                <a class="actions__content__block__btn smss-btn smss-btn--primary smss-btn--right" href="mailto:info@semoss.org">Contact Us</a>
            </div>
        </div>
    </div>
</div>
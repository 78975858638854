<script>
  export let data;
</script>

<style lang="scss">.default-article {
  width: 60%;
  margin: 80px auto; }

img {
  width: 100%;
  height: auto; }

iframe {
  width: 100%;
  height: auto; }

p {
  white-space: pre-wrap; }

@media only screen and (max-width: 1080px) {
  .default-article {
    width: 80%; } }

/*# sourceMappingURL=x.map */</style>

<div class="default-article">
  <h3>{data.title}</h3>
  <p>{data.text}</p>
  {#if data.media && data.media.type && data.media.type === 'image' && data.media.source.length > 0}
    <img src={data.media.source} alt="use-case-image" />
  {/if}
  {#if data.media && data.media.type && data.media.type === 'iframe' && data.media.source.length > 0}
    <iframe frameBorder="0" src={data.media.source} />
  {/if}
</div>

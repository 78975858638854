<script>
    import Card from "../components/Card.svelte";
    import UseCaseDetail from "./UseCaseDetail.svelte";
    import {menuOptions, useCases} from "../data/UseCasesOverview/usecases.json";
    import { Router, Route } from "svelte-routing";
    import { onMount, onDestroy} from "svelte";
    import { boxshadowHeader } from "../store.js";
    let searchTerm = "";
    let currentPage = menuOptions[0];
    let searchVisible = false;
    let cards = useCases;
    let filteredCards = cards;
    function filterCards() {
        if (currentPage === "All") {
            filteredCards = cards;
        } else {
            filteredCards = cards.filter(x => x.subtitle.includes(currentPage));
        }
        if (searchTerm.length > 0) {
            filteredCards = filteredCards.filter(x => {
            return x.title.toUpperCase().includes(searchTerm);
            })
        }
    }

    function searchCards(ev) {
        searchTerm = ev.target.value.toUpperCase();
        filterCards();
    }   

    function updatePage(page) {
        currentPage = page.item;
        filterCards();
    }

    function toggleSearch() {
        searchVisible = !searchVisible;
    }

    function scrollPosition(ev) {
        let position;
        if(window && window.scrollY){
            position = window.scrollY;
        }else if (document.documentElement && document.documentElement.scrollTop) {
            position = document.documentElement.scrollTop;
        }else {
            position = document.body.scrollTop;
        }
        if (position !== 0) {
            boxshadowHeader.set(true);
        } else {
            boxshadowHeader.set(false);
        }
    }

    onMount(() => {
        document.addEventListener("scroll", scrollPosition);
        window.scrollTo(0,0);
    });

    onDestroy(() => {
        document.removeEventListener("scroll", scrollPosition);
        boxshadowHeader.set(false);
    })
</script>

<style lang="scss">.use-cases {
  width: 80%;
  margin: auto; }

h2 {
  margin-top: 0;
  font-weight: 400; }

h5 {
  margin: 0; }

ul {
  list-style: none;
  padding: 0; }

li {
  cursor: pointer;
  margin: 10px 0;
  color: #6e758b; }

.active {
  color: #00a8c1; }

.container {
  display: flex;
  margin: 20px 0; }

.holder {
  width: 30%; }

.menu {
  width: 30%; }

.top {
  width: 70%;
  display: flex;
  justify-content: space-between;
  align-items: center; }
  .top--title {
    display: none; }

.content {
  width: 70%;
  display: flex;
  flex-wrap: wrap; }

.item {
  width: 33%;
  padding: 20px;
  min-width: 240px; }

svg {
  width: 20px;
  height: auto; }

.search {
  position: relative; }
  .search * {
    outline: none; }

input {
  width: 23px;
  height: 23px;
  opacity: 0;
  border: 1px solid transparent;
  transition: all 0.3s ease-in-out; }

input:focus {
  border-bottom-color: #e7e7e7;
  width: 200px;
  opacity: 1; }

button {
  position: absolute;
  top: 0;
  right: 0;
  width: 23px;
  height: 23px;
  pointer-events: none;
  display: inline-block;
  background: none; }

@media only screen and (max-width: 1080px) {
  .menu {
    display: none; }
  .top {
    width: 100%; }
    .top--page {
      display: none; }
    .top--title {
      display: block; }
  .content {
    width: 100%; }
  .holder {
    display: none; } }

@media only screen and (max-width: 768px) {
  .item {
    width: 50%; } }

@media only screen and (max-width: 611px) {
  .item {
    width: 100%; } }

/*# sourceMappingURL=x.map */</style>
<div class="use-cases">
    <div class="container">
        <div class="holder"></div>
        <div class="top">
            <h5 class="top--page">{currentPage}</h5>
            <h5 class="top--title">Use Cases</h5>
            <div class="search">
                <input on:keyup="{ev => searchCards(ev)}">
                <button on:click="{toggleSearch}">
                    <img src="src/resources/img/search_icon.svg" alt="search-icon">
                </button>
            </div>
        </div>
    </div>
    <div class="container">
        <div class="menu">
            <h2>Use Cases</h2>
            <ul>
                {#each menuOptions as item}
                <li on:click={event => updatePage({item})} class:active={currentPage === item}>
                    {item}
                </li>
                {/each}
            </ul>
        </div>
        <div class="content">
            {#if filteredCards.length > 0}
                {#each filteredCards as item}
                    <div class="item">
                        <Card title={item.title} image={item.image} subtitle={item.subtitle} link={item.path}/>
                    </div>
                {/each}
            {:else}
                <p>No use cases found.</p>
            {/if}
        </div>
    </div>
</div>
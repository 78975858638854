<script>
  export let data;

  let attributes = {
    style: "",
    class: ""
  };

  /**
   * @name formatStyle
   * @desc create the style attribute based on style object
   * @param {object} styleObj - style to convert to a inline string
   * @returns {string} style to render
   **/
  function formatStyle(styleObj) {
    // check if it exists
    if (
      typeof styleObj !== "object" ||
      styleObj === null ||
      Object.keys(styleObj).length === 0
    ) {
      return "";
    }

    let styleString = "";
    for (let property in styleObj) {
      let name = property.replace(
        /([A-Z])/g,
        matches => `-${matches[0].toLowerCase()}`
      );

      styleString += `${name}:${styleObj[property]};`;
    }

    return styleString;
  }

  /**
   * @name formatClass
   * @desc create the class attribute based on position
   * @param {string} position - position to consider
   * @param {string} className - custom class name
   * @returns {string} class to render
   **/
  function formatClass(position, className) {
    let classArray = [];

    if (position === "left") {
      classArray.push("side-article__left");
    } else if (position === "right") {
      classArray.push("side-article__right");
    } else if (position === "center") {
      classArray.push("side-article__center");
    }

    if (className) {
      classArray.push(className);
    }

    return classArray.join(" ");
  }

  /**
   * @name initialize
   * @desc initialize the module
   * @returns {void}
   **/
  function initialize() {
    // format the style
    attributes.style = formatStyle(data.style || {});
    attributes.class = formatClass(data.position || "", data.class);
  }

  initialize();
</script>

<style lang="scss">.side-article {
  margin: 80px 20%;
  overflow: hidden; }
  .side-article > img {
    width: 60%; }
  .side-article > iframe {
    height: 300px;
    height: 60vh;
    width: 60%; }
  .side-article > p {
    white-space: pre-wrap; }
  .side-article__left {
    float: left;
    margin: 0 40px 40px 0; }
  .side-article__right {
    float: right;
    margin: 0 0 40px 40px; }
  .side-article__center {
    display: block;
    width: 100% !important;
    margin: 40px auto; }

@media only screen and (max-width: 1080px) {
  .side-article {
    width: 80%;
    margin: 80px auto; }
    .side-article > img {
      width: 100%; }
    .side-article > iframe {
      width: 100%; } }

/*# sourceMappingURL=x.map */</style>

<div class="side-article">
  <h3>{data.title}</h3>
  {#if data.media && data.media.type && data.media.type === 'image' && data.media.source.length > 0}
    <img
      src={data.media.source}
      alt="use-case-image"
      style={attributes.style}
      class={attributes.class} />
  {/if}
  {#if data.media && data.media.type && data.media.type === 'iframe' && data.media.source.length > 0}
    <iframe
      frameBorder="0"
      src={data.media.source}
      style={attributes.style}
      class={attributes.class} />
  {/if}
  <p>{data.text}</p>
</div>

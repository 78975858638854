<script>
    import ResourceCard from "../components/ResourceCard.svelte";
    import { Link } from "svelte-routing";
    import carousel from "../data/Home/carousel.json";
    import features from "../data/Home/features.json";
    import usecases from "../data/Home/usecases.json";
    import headline from "../data/Home/headline.json";
    import { onMount, onDestroy } from "svelte";
    import { showSignup, boxshadowHeader } from "../store.js";

    let carouselSlide = 0;
    let headlineSlide = 0;
    const headlineLastSlide = headline.data.length - 1;
    let headlineInterval;

    function openSignup() {
        showSignup.set(true);
    }
    
    // Updates the current slide of the carousel
    function updateCarousel (number) {
        carouselSlide = number;
    }

    function scrollPosition(ev) {
        let position;
        if(window && window.scrollY){
            position = window.scrollY;
        }else if (document.documentElement && document.documentElement.scrollTop) {
            position = document.documentElement.scrollTop;
        }else {
            position = document.body.scrollTop;
        }
        if (position !== 0) {
            boxshadowHeader.set(true);
        } else {
            boxshadowHeader.set(false);
        }
    }
    function changeHeadline(){
        if (headlineSlide === headlineLastSlide) {
            headlineSlide = 0;
        } else {
            headlineSlide ++;
        }
    }
    onMount(() => {
        document.addEventListener("scroll", scrollPosition);
        window.scrollTo(0,0);
        headlineInterval = setInterval(changeHeadline, 5000);
    });

    onDestroy(() => {
        document.removeEventListener("scroll", scrollPosition);
        boxshadowHeader.set(false);
        if (headlineInterval) {
            clearInterval(headlineInterval);
        }
    })
</script>

<style lang="scss">h5 {
  margin: 0;
  font-weight: 300; }

.carousel {
  display: flex;
  align-items: center;
  width: 80%;
  margin: 40px auto; }
  .carousel__menu {
    width: 30%; }
    .carousel__menu__item {
      pointer-events: none;
      height: 100px;
      margin-bottom: 30px;
      border-bottom: 1px solid #e7e7e7; }
      .carousel__menu__item > div {
        font-size: .875em; }
      .carousel__menu__item__subtitle {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden; }
      .carousel__menu__item__top {
        display: flex;
        position: relative; }
        .carousel__menu__item__top::after {
          content: " ";
          width: 6px;
          height: 6px;
          display: inline;
          background: #e7e7e7;
          position: absolute;
          top: 0;
          left: -43px; }
        .carousel__menu__item__top::before {
          content: " ";
          width: 40px;
          height: 40px;
          border-radius: 50%;
          background: #00a8c1;
          display: inline;
          position: absolute;
          top: -17px;
          left: -60px;
          opacity: 0; }
        .carousel__menu__item__top__number {
          margin-top: -6px;
          font-weight: 600; }
  .carousel__content {
    width: 70%; }
    .carousel__content__image {
      width: 80%;
      height: 100%;
      margin-left: auto;
      position: relative; }
      .carousel__content__image:before {
        background: url(/src/resources/img/dots2.svg);
        background-repeat: no-repeat;
        background-size: contain;
        content: "";
        height: 200px;
        width: 200px;
        display: block;
        position: absolute;
        z-index: -1;
        right: -100px;
        bottom: -100px; }
      .carousel__content__image:after {
        content: "";
        background: url(/src/resources/img/carousel-shadow.svg);
        height: 100%;
        width: 100%;
        display: block;
        position: absolute;
        background-size: 100% auto;
        background-repeat: no-repeat;
        background-position: bottom;
        top: 30%;
        z-index: -1; }
      .carousel__content__image img {
        width: 100%;
        height: auto; }
    .carousel__content__text {
      width: calc(80%-100px);
      margin-left: 20%;
      margin-top: 10%;
      color: #4c4d5f; }
    .carousel__content__block {
      position: absolute;
      text-align: center; }
      .carousel__content__block--grey {
        height: 25%;
        width: 12.5%;
        top: 25%;
        left: -6.25%;
        background: #4c4d5f; }
      .carousel__content__block--light {
        height: 40%;
        width: 20%;
        left: 60%;
        bottom: -20%;
        background: #f5faf8; }
      .carousel__content__block--teal {
        height: 25%;
        width: 12.5%;
        top: 60%;
        right: -6.25%;
        background: #00a8c1; }
      .carousel__content__block img {
        width: auto;
        height: 40%;
        margin: 30% 0; }
  .carousel ul {
    list-style: none;
    padding: 0; }
  .carousel li {
    padding-left: 40px;
    height: 130px;
    border-left: 1px solid #e7e7e7;
    color: #4c4d5f; }
  .carousel li:last-child {
    border-left: 1px solid transparent !important; }
    .carousel li:last-child .carousel__menu__item {
      border-bottom: 1px solid transparent; }
  .carousel li:hover {
    cursor: pointer;
    color: #00a8c1; }
  .carousel li.active {
    color: #00a8c1; }
    .carousel li.active .carousel__menu__item__top::after {
      background: #00a8c1; }
    .carousel li.active .carousel__menu__item__top::before {
      opacity: .1;
      transition: .4s linear; }
  .carousel li.before {
    border-left: 1px solid #00a8c1; }
    .carousel li.before .carousel__menu__item__top::after {
      background: #00a8c1; }

.carousel--mobile {
  display: none;
  padding: 40px 0 80px; }
  .carousel--mobile ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
    list-style: none;
    padding: 0; }
  .carousel--mobile li {
    padding: 20px;
    margin: 8px;
    border-radius: 8px;
    background: #f8f8f9;
    color: #6e758b; }
    .carousel--mobile li.active {
      color: #00a8c1;
      background: #f5faf8;
      box-shadow: 0px 2px 3px 0px #efefef; }
  .carousel--mobile__content {
    width: 80%;
    margin: auto;
    text-align: center; }
    .carousel--mobile__content__img {
      height: auto;
      width: 100%; }
      .carousel--mobile__content__img img {
        width: 100%;
        height: auto; }
    .carousel--mobile__content h6 {
      margin: 20px; }

.features {
  background: url(/src/resources/img/features_bg.png);
  background-repeat: repeat-x;
  background-size: auto;
  position: relative;
  z-index: -1; }
  .features:before {
    background: url(/src/resources/img/curved-lines.png);
    background-repeat: no-repeat;
    background-size: auto 100%;
    content: "";
    height: 350px;
    width: 100%;
    display: block;
    position: absolute;
    background-position: right;
    right: 0;
    top: -100px; }
  .features__content {
    width: 80%;
    margin: auto;
    padding: 50px 0 100px; }
  .features__container {
    display: flex;
    justify-content: space-between;
    height: 400px; }
  .features__block {
    width: 33%;
    min-width: 200px;
    padding: 0 20px;
    align-self: flex-end; }
    .features__block__text {
      background: #ffffff; }
      .features__block__text p {
        color: #4c4d5f; }
    .features__block:nth-child(even) {
      align-self: flex-start; }
  .features h1 {
    margin-top: 0;
    font-weight: 300; }

.use-case {
  position: relative;
  margin: 200px 0; }
  .use-case__content {
    display: flex;
    align-items: center;
    margin: 100px auto;
    width: 80%; }
  .use-case__text {
    width: 50%;
    padding-right: 40px; }
    .use-case__text h2 {
      font-weight: 300;
      color: #00a8c1; }
    .use-case__text p {
      white-space: pre-wrap;
      color: #4c4d5f; }
  .use-case__image {
    width: 50%;
    height: 100%;
    position: relative; }
    .use-case__image__container {
      width: 100%;
      height: 100%;
      position: relative; }
      .use-case__image__container img {
        width: 100%;
        height: auto; }
      .use-case__image__container:before {
        position: absolute;
        content: "";
        width: 100%;
        height: 100%;
        background: url(/src/resources/img/usecase-shadow.svg);
        background-repeat: no-repeat;
        background-size: 100% auto;
        background-position: bottom;
        z-index: -1;
        bottom: -25%; }
  .use-case__full-image {
    width: 100%;
    display: none; }
    .use-case__full-image img {
      width: 100%;
      height: auto; }
  .use-case:nth-child(even) .use-case__content {
    flex-direction: row-reverse; }
    .use-case:nth-child(even) .use-case__content .use-case__text {
      padding-right: 0;
      padding-left: 40px; }
  .use-case a {
    color: #ffffff;
    text-decoration: none !important; }
  .use-case__bg-0 {
    margin-top: 0px; }
    .use-case__bg-0 .use-case__image:after {
      content: "";
      display: block;
      height: 115%;
      width: 100%;
      background: url(/src/resources/img/leaf.svg);
      background-size: contain;
      background-repeat: no-repeat;
      position: absolute;
      z-index: -1;
      right: -25%;
      top: -30%;
      background-position: right; }
    .use-case__bg-0 .use-case__image:before {
      background: url(/src/resources/img/dots2.svg);
      background-repeat: no-repeat;
      background-size: contain;
      content: "";
      height: 200px;
      width: 200px;
      display: block;
      position: absolute;
      z-index: -1;
      left: 60px;
      bottom: -60px; }
  .use-case__bg-1 .use-case__image:before {
    content: "";
    display: block;
    height: 170%;
    width: 100%;
    background: url(/src/resources/img/circle.svg);
    background-size: auto 100%;
    background-repeat: no-repeat;
    position: absolute;
    z-index: -1;
    top: -35%;
    background-position: center; }
  .use-case__bg-1 .use-case__image:after {
    background: url(/src/resources/img/dots2.svg);
    background-repeat: no-repeat;
    background-size: contain;
    content: "";
    height: 200px;
    width: 200px;
    display: block;
    position: absolute;
    z-index: -1;
    left: -45px;
    bottom: -90px; }
  .use-case__bg-2:before {
    content: "";
    display: block;
    height: 90%;
    width: 40%;
    background: url(/src/resources/img/rectangle.svg);
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
    z-index: -1;
    right: 0;
    top: -15%;
    background-position: right; }
  .use-case__bg-2 .use-case__image:before {
    background: url(/src/resources/img/dots2.svg);
    background-repeat: no-repeat;
    background-size: contain;
    content: "";
    height: 200px;
    width: 200px;
    display: block;
    position: absolute;
    z-index: -1;
    right: 80px;
    bottom: -90px; }

:global(.buttonLink) {
  color: #ffffff !important;
  text-decoration: none !important; }

.actions {
  background: url(/src/resources/img/straight-lines.png);
  background-repeat: no-repeat;
  background-position-x: left; }
  .actions__content {
    width: 80%;
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding-top: 70px; }
    .actions__content div:first-child {
      border-bottom: 1px solid #e4e5e7; }
  .actions__block {
    padding-bottom: 40px; }
    .actions__block__contact {
      align-self: center; }
      .actions__block__contact__btn {
        position: relative; }
        .actions__block__contact__btn:before {
          content: "";
          height: 100px;
          width: 106px;
          background-repeat: no-repeat;
          display: block;
          position: absolute;
          background: url(/src/resources/img/small_leaf.svg);
          background-size: contain;
          top: -15px;
          right: -73px;
          z-index: -1; }
  .actions h3 {
    background: #ffffff;
    font-weight: 300; }
  .actions .arrow {
    height: 12px;
    margin: 3px 10px 0; }

.headline {
  height: calc(90vh - 50px);
  width: 100%;
  background: url(/src/resources/img/headline_background.svg);
  background-size: cover;
  position: relative; }
  .headline:before {
    background: url(/src/resources/img/headline_shape.svg);
    content: "";
    width: 55%;
    height: 100%;
    position: absolute;
    background-repeat: no-repeat;
    background-position: left;
    background-size: contain;
    z-index: 1; }
  .headline__content {
    width: 80%;
    height: 100%;
    margin: 0 10%;
    display: flex;
    align-items: center; }
  .headline__text {
    width: 55%;
    height: 100%;
    display: flex;
    flex-direction: column;
    z-index: 2; }
    .headline__text__top {
      height: 45%;
      position: relative; }
    .headline__text__bottom {
      height: 55%;
      padding-right: 10%; }
  .headline__tagline {
    opacity: 0;
    position: absolute;
    bottom: 0;
    width: 100%;
    padding-right: 10%; }
  .headline__image {
    width: 45%;
    height: 100%;
    position: relative; }
    .headline__image__container {
      opacity: 0;
      position: absolute;
      height: 100%;
      width: 90%;
      margin-left: 10%; }
  .headline h1 {
    font-weight: 300;
    color: #00a8c1;
    margin: 0; }
  .headline h2 {
    color: #4c4d5f; }

.activeSlide {
  opacity: 1 !important;
  transition: opacity ease-in 1.5s; }

@media only screen and (max-width: 1024px) {
  .headline {
    height: 40vh;
    background: url(/src/resources/img/download_bg.svg);
    background-repeat: no-repeat; }
  .headline:before {
    display: none; }
  .headline__image {
    display: none; }
  .headline__text {
    width: 100%;
    text-align: center; }
    .headline__text__bottom {
      padding: 0; }
  .headline__tagline {
    padding: 0; } }

@media only screen and (max-width: 768px) {
  .use-case__image {
    display: none; }
  .use-case__text {
    width: 100%;
    padding: 0 !important; }
  .use-case__full-image {
    display: block; }
  .use-case__bg-0:before {
    display: none; }
  .use-case__bg-1:before {
    display: none; }
  .use-case__bg-2:before {
    display: none; }
  .features:before {
    display: none; }
  .actions__card {
    display: none; }
  .actions__content {
    flex-wrap: wrap; }
  .actions__block {
    width: 100%; } }

@media only screen and (max-width: 1080px) {
  .carousel {
    display: none; }
  .carousel--mobile {
    display: block; }
  .features {
    background: none; }
    .features__content {
      padding: 0; }
    .features__container {
      flex-wrap: wrap;
      height: 100%; }
    .features__block {
      margin: 20px auto;
      padding: 0; }
  .use-case {
    margin: 100px 0; }
  .headline {
    height: 60vh; }
  .actions {
    background: none; }
  h1 {
    font-size: 36px; } }

/*# sourceMappingURL=x.map */</style>

<div class="home">
    <div class="headline">
            <div class="headline__content" >
                <div class="headline__text">
                    <div class="headline__text__top">
                        {#each headline.data as item, i}
                            <div class="headline__tagline" class:activeSlide={headlineSlide === i}>
                                <h1>{item.title}</h1>
                            </div>
                        {/each}
                    </div>
                    <div class="headline__text__bottom">
                        <p>Better ideas through exploratory analysis. Accelerate your enterprise from business intelligence to intelligent business.</p>
                        <button class="smss-btn smss-btn--right smss-btn--primary" on:click={openSignup}>Sign up</button>
                    </div>
                </div>
                <div class="headline__image">
                    {#each headline.data as item, i}
                        <div class="headline__image__container"
                            class:activeSlide={headlineSlide === i} 
                            style="background: url({item.img}); background-size: contain; background-repeat: no-repeat; background-position: center;">
                        </div>
                    {/each}
                </div>
            </div>
    </div>
    <div class="carousel--mobile">
            <ul>
                {#each carousel.data as item, i}
                <li on:click={event => updateCarousel(i, event)}
                    class:active={carouselSlide === i}>
                    <div>
                        <h5>{item.title}</h5>
                    </div>
                </li>
                {/each}
            </ul>
            <div class="carousel--mobile__content">
                <div class="carousel--mobile__content__img">
                    <img src="{carousel.data[carouselSlide].image}" alt="app-screen">
                </div>
                <h6>{carousel.data[carouselSlide].subtitle}</h6>
                <p>{carousel.data[carouselSlide].description}</p>
            </div>
    </div>
    <div class="carousel">
        <div class="carousel__menu">
            <ul>
                {#each carousel.data as item, i}
                <li on:click={event => updateCarousel(i, event)}
                    class:active={carouselSlide === i}
                    class:before={carouselSlide-1 === i}>
                    <div class="carousel__menu__item">
                        <div class="carousel__menu__item__top">
                            <div class="carousel__menu__item__top__number">{item.number}</div>
                        </div>
                        <h5>{item.title}</h5>
                        <div class="carousel__menu__item__subtitle">{item.subtitle}</div>
                    </div>
                </li>
                {/each}
            </ul>
        </div>
        <div class="carousel__content">
            <div class="carousel__content__image">
                <img src="{carousel.data[carouselSlide].image}" alt="app-screen">
                <div class="carousel__content__block carousel__content__block--grey">
                    <img src="{carousel.data[carouselSlide].grey}" alt="icon">
                </div>
                <div class="carousel__content__block carousel__content__block--light">
                    <img src="{carousel.data[carouselSlide].light}" alt="icon">
                </div>
                <div class="carousel__content__block carousel__content__block--teal">
                    <img src="{carousel.data[carouselSlide].teal}" alt="icon">
                </div>
            </div>
            <p class="carousel__content__text">{carousel.data[carouselSlide].description}</p>
        </div>
    </div>
    <div class="features">
        <div class="features__content">
            <h1>Why SEMOSS?</h1>
            <div class="features__container">
                {#each features.data as item}
                <div class="features__block">
                    <img src="{item.image}" alt="feature-img" height="100px" width="auto">
                    <div class="features__block__text">
                        <h3>{item.title}</h3>
                        <p>{item.description}</p>
                    </div>
                </div>
                {/each}
            </div>
        </div>
    </div>
    {#each usecases.data as item, i}
        <div class="use-case use-case__bg-{i}">
            <div class="use-case__content">
                <div class="use-case__text">
                    <h2>{item.title}</h2>
                    <div class="use-case__full-image">
                        <img src="{item.image}" alt="use-case-image">
                    </div>
                    <p>{item.description}</p>
                    <Link getProps={() => {return {class: "buttonLink"}}} to="{item.link}">
                        <button class="smss-btn--right smss-btn--primary">Read More</button>
                    </Link>
                </div>
                <div class="use-case__image">
                    <div class="use-case__image__container">
                        <img src="{item.image}" alt="use-case-image">
                    </div>
                </div>
            </div>
        </div>
    {/each}
    <div class="actions">
        <div class="actions__content">
            <div class="actions__block">
                <h3>Interested in trying?</h3>
                <a href="https://play.semoss.org/SemossWeb/#!/login" target="_blank">
                    Try it free
                    <img class="arrow" src="src/resources/img/arrow.svg" alt="arrow">
                </a><br>
                <Link to="download">
                    Download
                    <img class="arrow" src="src/resources/img/arrow.svg" alt="arrow">
                </Link>
            </div>
            <div class="actions__block">
                <h3>Want to learn more?</h3>
                <a href="http://semoss.org/SemossDocumentation/" target="_blank">
                    Read our user guide
                    <img class="arrow" src="src/resources/img/arrow.svg" alt="arrow">
                </a><br>
            </div>
            <div class="actions__block actions__block__contact">
                <a class="actions__block__contact__btn smss-btn smss-btn--primary smss-btn--right" href="mailto:info@semoss.org">Contact Us</a>
            </div>
        </div>
    </div>
</div>
<script>
    import Card from "../components/Card.svelte";
    import VideoCard from "../components/VideoCard.svelte";
    import ResourceCard from "../components/ResourceCard.svelte";
    import cardData from "../data/dummydata.json";
</script>

<style lang="scss">.resources {
  width: 80%;
  margin: auto; }

ul {
  padding: 0;
  list-style-type: none; }

h2 {
  margin-top: 0; }

.container {
  display: flex; }

.holder {
  width: 20%; }

.top {
  width: 80%; }

.menu {
  width: 20%; }

.content {
  width: 80%; }

.grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  grid-gap: 3rem; }
  .grid--video {
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr)); }

/*# sourceMappingURL=x.map */</style>

<div class="resources">
    <div class="container">
        <div class="holder"></div>
        <div class="top">
            <h4>Articles</h4>
        </div>
    </div>
    <div class="container">
        <div class="menu">
            <h2>Resources</h2>
            <p>Explore a collection of articles, videos and other resources.</p>
            <ul>
                <li>
                    <a href="#articles">Articles</a>
                </li>
                <li>
                    <a href="#videos">Videos</a>
                </li>
                <li>
                    <a href="#resources">Resources</a>
                </li>
            </ul>
        </div>
        <div class="content">
            <div id="articles" class="grid">
                {#each cardData.data1 as item}
                    <Card title={item.title} image={item.image} subtitle={item.subtitle} avatar={item.avatar} date={item.date} />
                {/each}
            </div>
            <h4>Videos</h4>
            <div id="videos" class="grid grid--video">
                {#each cardData.data3 as item}
                    <VideoCard image={item.image} title={item.title} description={item.description} />
                {/each}
            </div>
            <h4>Resources</h4>
            <div id="resources" class="grid">
                <ResourceCard title="User Guide" link="http://semoss.org/SemossDocumentation/" />
            </div>
        </div>
    </div>
</div>
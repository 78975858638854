* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

body {
  height: 100%;
  width: 100%;
  margin: 0;
  font-family: "Libre Franklin", sans-serif;
  font-size: 16px;
  color: #1f2238;
  line-height: 28px;
  font-weight: 400;
  overflow: auto; }

:root {
  --color-smoke-grey: #efefef;
  --color-dark-smoke: #e4e5e7;
  --color-silver: #6e758b;
  --color-steel: #4c4d5f;
  --color-black: #1f2238;
  --color-dark: #17233c;
  --color-teal: #00a8c1;
  --color-light: #f5faf8; }

h1 {
  font-size: 48px;
  line-height: 56px;
  letter-spacing: -0.4px; }

h2 {
  font-size: 36px;
  line-height: 42px;
  letter-spacing: -0.4px; }

h3 {
  font-size: 24px;
  line-height: 32px;
  letter-spacing: -0.4px; }

h4 {
  font-size: 24px;
  line-height: 32px;
  letter-spacing: -0.4px; }

h5 {
  font-size: 20px;
  line-height: 26px;
  letter-spacing: -0.4px; }

h6 {
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0px; }

.smss-caption {
  font-size: 18px; }
  .smss-caption--small {
    font-size: 14px; }

.smss-footnote {
  font-size: 12px; }

.smss-light {
  font-weight: 300; }

.smss-semibold {
  font-weight: 600; }

a {
  text-decoration: none;
  color: #00a8c1; }

a:hover {
  text-decoration: underline;
  color: #17233c; }

a.nav {
  font-size: .75em;
  color: #1f2238; }

a.nav:hover {
  color: #00a8c1;
  text-decoration: none; }

a.nav.active {
  color: #00a8c1; }

.header__menu__overlay .nav {
  color: #ffffff !important;
  font-size: 36px;
  padding: 20px 0;
  margin: 20px 0;
  display: inline-block;
  white-space: no-wrap; }

button {
  text-transform: uppercase;
  background: #efefef;
  color: #4c4d5f;
  height: 40px;
  padding: 0 20px;
  outline: none;
  border: none;
  font-size: 12px;
  font-family: "Libre Franklin", sans-serif; }

button:hover {
  cursor: pointer;
  background: #6e758b;
  color: #ffffff; }

.smss-btn {
  display: inline-block;
  text-transform: uppercase;
  background: #efefef;
  color: #4c4d5f;
  height: 40px;
  line-height: 40px;
  padding: 0 20px;
  outline: none;
  border: none;
  font-size: 12px; }
  .smss-btn:hover {
    color: #ffffff;
    background: #6e758b;
    text-decoration: none; }
  .smss-btn--primary {
    color: #ffffff;
    background: #00a8c1; }
    .smss-btn--primary:hover {
      background: #1f2238; }
  .smss-btn--left {
    border-radius: 0 8px 0 8px; }
  .smss-btn--right {
    border-radius: 8px 0 8px 0; }

.form-group {
  position: relative;
  display: flex;
  width: 100%;
  padding: 8px 0;
  flex-direction: column-reverse; }

input {
  border: 1px solid transparent;
  border-bottom: 1px solid #efefef;
  background: transparent;
  height: 40px;
  outline: none;
  font-family: "Libre Franklin", sans-serif;
  font-size: 16px; }
  input:focus {
    border-bottom-color: #00a8c1; }
    input:focus + .form-group__label {
      color: #00a8c1; }

textarea {
  min-height: 80px;
  outline: none;
  border: 1px solid #efefef; }
  textarea:focus {
    border: 1px solid #00a8c1; }
    textarea:focus + .form-group__label {
      color: #00a8c1; }

select {
  border: 1px solid #efefef;
  outline: none;
  height: 40px;
  font-family: "Libre Franklin", sans-serif;
  font-size: 16px; }
  select:focus {
    border: 1px solid #00a8c1; }
    select:focus + .form-group__label {
      color: #00a8c1; }

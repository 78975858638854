<script>
    import { onMount } from "svelte";
    import { Router, Link } from "svelte-routing";
    import NavLink from "./NavLink.svelte";
    import { showSignup, transparentHeader, staticHeader, inverseHeader, borderedHeader, boxshadowHeader } from "../store.js";
    let open = false;
    function openMenu() {
        document.getElementById("sideMenu").style.right = "0";
        open = true;
    }
    function closeMenu(){
        document.getElementById("sideMenu").style.right = "-100%";
        open = false;
    }
    function openSignup(close) {
        showSignup.set(true);
        if(close) {
            closeMenu();
        }
    }


</script>

<style lang="scss">ul {
  list-style: none;
  padding: 0; }

.header {
  position: fixed;
  top: 0;
  background: #ffffff;
  width: 100%;
  z-index: 3; }
  .header__side {
    width: 185px; }
    .header__side button {
      margin-right: 10px; }
  .header__logo img {
    height: 32px;
    width: auto; }
  .header__links li {
    display: inline-block;
    text-transform: uppercase;
    font-weight: 700;
    margin: 0 20px; }
  .header__content {
    width: 80%;
    height: 50px;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: space-between; }
  .header__menu {
    display: none; }
    .header__menu__open {
      width: 40px;
      background: transparent;
      padding: 0; }
      .header__menu__open svg {
        height: 24px;
        width: auto;
        color: #4c4d5f; }
    .header__menu__overlay {
      display: none;
      right: -100%;
      height: 100%;
      width: 100%;
      position: fixed;
      z-index: 1;
      top: 0;
      background-color: black;
      background-color: rgba(0, 0, 0, 0.9);
      overflow-x: hidden;
      transition: 0.5s ease right; }
      .header__menu__overlay .nav {
        color: #ffffff;
        font-size: 36px;
        padding: 20px 0;
        margin: 20px 0;
        display: inline-block;
        white-space: no-wrap; }
      .header__menu__overlay__close {
        background: transparent;
        position: absolute;
        top: 20px;
        right: 20px;
        padding: 0;
        width: 40px; }
        .header__menu__overlay__close svg {
          height: 32px;
          width: auto;
          color: #ffffff; }
      .header__menu__overlay__content {
        position: relative;
        top: 25%;
        width: 100%;
        text-align: center; }

.static {
  position: absolute; }

.transparent {
  background: transparent; }

.border {
  border-bottom: 1px solid #efefef; }

.invert {
  color: #ffffff; }
  .invert :global(.nav) {
    color: #ffffff; }
  .invert svg {
    color: #ffffff; }

.boxshadow {
  box-shadow: 0px 2px 3px 0px #efefef; }

:global(.logo-link) {
  display: block;
  height: 32px; }

@media only screen and (max-width: 1024px) {
  .header__links {
    display: none; }
  .header__btns {
    display: none; }
  .header__menu {
    display: block; }
    .header__menu__overlay {
      display: block; } }

/*# sourceMappingURL=x.map */</style>

<div class="header" 
    class:static={$staticHeader} 
    class:transparent={$transparentHeader} 
    class:invert="{$inverseHeader}"
    class:border="{$borderedHeader}"
    class:boxshadow="{$boxshadowHeader}">
    <div class="header__content">
        <Router>
        <div class="header__side header__logo">
            <Link to="/" getProps={() => {return {class: "logo-link"}}}>
                {#if $inverseHeader}
                    <img src="src/resources/img/semoss_logo_white.svg" alt="logo">
                {:else}
                    <img src="src/resources/img/semoss_logo_dark.svg" alt="logo">
                {/if}
            </Link>
        </div>
        <div class="header__links">
            <ul>
                <li><NavLink to="use-cases">Use Cases</NavLink></li>
                <li><a class="nav" class:invert="{$inverseHeader}" href="http://semoss.org/SemossDocumentation/" target="_blank">User Guide</a></li>
                <li><NavLink to="download">Download</NavLink></li>
            </ul>
        </div>
        <div class="header__side header__btns">
            <button class="smss-btn smss-btn--left"
                on:click="{openSignup}">
                Sign Up
            </button>
            <a class="smss-btn smss-btn--right smss-btn--primary" href="https://play.semoss.org/SemossWeb/#!/login" target="_blank">Try It</a>
        </div>
        <div class="header__menu">
            <button class="header__menu__open" on:click="{openMenu}" class:invert="{$inverseHeader}">
                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="bars" class="svg-inline--fa fa-bars fa-w-14" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M16 132h416c8.837 0 16-7.163 16-16V76c0-8.837-7.163-16-16-16H16C7.163 60 0 67.163 0 76v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16z"></path></svg>
            </button>
            <div id="sideMenu" class="header__menu__overlay">
                <button class="header__menu__overlay__close" on:click="{closeMenu}">
                    <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="times" class="svg-inline--fa fa-times fa-w-11" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 352 512"><path fill="currentColor" d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"></path></svg>
                </button>
                <div class="header__menu__overlay__content" >
                <ul>
                    <li>
                        <NavLink to="/"><span on:click="{closeMenu}">Home</span></NavLink>
                    </li>
                    <li>
                        <NavLink to="use-cases"><span on:click="{closeMenu}">Use Cases</span></NavLink>
                    </li>
                    <li>
                        <a class="nav" href="http://semoss.org/SemossDocumentation/" target="_blank">User Guide</a>
                    </li>
                    <li>
                        <NavLink to="download"><span on:click="{closeMenu}">Download</span></NavLink>
                    </li>
                    <li>
                        <a class="nav" href="https://play.semoss.org/SemossWeb/#!/login" target="_blank">Try It</a>
                    </li>
                    <li>
                        <a class="nav" href="#" on:click="{ev => openSignup(true)}">Sign Up</a>
                    </li>
                </ul>
                </div>
            </div>
        </div>
        </Router>
    </div>
</div>

<script>
    import { showSignup } from "../store.js";
    let page = 1;
    function resetPage() {
        page = 1;
    }
    function closePopup(ev) {
        if (ev.target.id === "close-btn" || ev.target.id === "close-bg"){
            console.log(ev.target.id)
            showSignup.set(false);
            resetPage();
        }
    }
    function submitForm() {
        var formData = new URLSearchParams(new FormData(document.getElementById('sign-up-form')));
        formData.append("Submit", "Submit");
        formData.append("campaign_id", "971ede46-91ed-1c76-6a9c-5d8028c7e3f8");
        formData.append("moduleDir", "Contacts");
        fetch('https://semoss.org/crm/index.php?entryPoint=WebToPersonCapture', {
            method: 'POST',
            body: formData,
            headers:{
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        })
        .then(response => {
            if (response.status === 200) {
                page = 3;
            }
        })
        .catch(function(error) {
            console.log("There was an error: ", error);
            page = 2;
        })
    }
</script>

<style lang="scss">.sign-up__overlay {
  position: fixed;
  display: none;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 100;
  cursor: pointer; }

.active {
  display: block; }

.sign-up__form {
  width: 40%;
  min-width: 300px;
  height: 80vh;
  overflow: auto;
  margin: auto;
  padding: 20px;
  background: #ffffff;
  position: relative;
  top: 10vh;
  text-align: center; }

form {
  text-align: left; }

.sign-up__close-btn {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  color: #e4e5e7;
  padding: 0;
  width: 40px; }
  .sign-up__close-btn:hover {
    color: #4c4d5f; }
  .sign-up__close-btn svg {
    height: 16px;
    width: auto;
    pointer-events: none; }

input {
  width: 100%; }

select {
  width: 100%; }

h3 {
  text-align: center;
  margin: 0;
  padding: 20px 0 10px; }

p {
  text-align: center;
  font-size: 0.875em;
  margin: 0;
  padding-bottom: 20px;
  color: #6e758b; }

a {
  font-size: .875em;
  display: inline-block; }

/*# sourceMappingURL=x.map */</style>
<div class="sign-up__overlay" class:active={$showSignup} on:click="{ev => closePopup(ev)}" id="close-bg">
    <div class="sign-up__form">
        <button class="sign-up__close-btn" id="close-btn" on:click="{ev => closePopup(ev)}">
            <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="times" class="svg-inline--fa fa-times fa-w-11" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 352 512"><path fill="currentColor" d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"></path></svg>
        </button>
        {#if page === 2}
        <div>
            <h3>
                There was an error submitting your information. Please try again or 
                <span><a href="mailto:info@semoss.org">contact us</a></span>.
            </h3>
            <button class="smss-btn--right smss-btn--primary" on:click={resetPage}>Back</button>
        </div>
        {:else if page === 3}
            <h3>Thank you for signing up.</h3>
            <a class="smss-btn smss-btn--right smss-btn--primary" href="https://play.semoss.org/SemossWeb/#!/login" target="_blank">Try SEMOSS Now</a>
        {:else}
            <div>
                <h3>Sign Up</h3>
                <p>Sign up to keep up-to-date with SEMOSS.</p>
                <form id="sign-up-form" method="post" on:submit|preventDefault="{submitForm}">
                    <div class="form-group">
                        
                        <input name="first_name" value="">
                        <div class="form-group__label">First Name</div>
                    </div>
                    <div class="form-group">
                        
                        <input name="last_name" value="">
                        <div class="form-group__label">Last Name</div>
                    </div>
                    <div class="form-group">
                        
                        <input name="email1" value="" required>
                        <div class="form-group__label">Email</div>
                    </div>
                    <div class="form-group">
                        <select name="lead_source">
                            <option value="Conference">Conference</option>
                            <option value="Internet_Social_Media">Internet/Social Media</option>
                            <option value="Colleagues_Friends_Family">Colleagues/Friends/Family</option>
                            <option value="Other">Other</option>
                        </select>
                        <div class="form-group__label">How did you hear about SEMOSS?</div>
                    </div>
                    <div class="form-group">
                        <textarea name="description" value=""></textarea>
                        <div class="form-group__label">Leave a message</div>
                    </div>
                    <div class="form-group">
                        <!-- TO DO: Submit then redirect to play.semoss.org -->
                        <button class="smss-btn--right smss-btn--primary" type="submit">Get Started</button>
                    </div>
                </form>
            </div>
        {/if}
    </div>
</div>